import { template as template_022d9d2362ac4e979619f89301c893fe } from "@ember/template-compiler";
const WelcomeHeader = template_022d9d2362ac4e979619f89301c893fe(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
