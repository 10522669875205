import { template as template_f7211748250949729b93568199f9c9bd } from "@ember/template-compiler";
const FKLabel = template_f7211748250949729b93568199f9c9bd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
