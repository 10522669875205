import { template as template_eb2d722a1d384c848ab0310a5ad63fe0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_eb2d722a1d384c848ab0310a5ad63fe0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
