import { template as template_085966bd7a1e4ad0a6adf00ee2d0a6bc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_085966bd7a1e4ad0a6adf00ee2d0a6bc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
